import { useState } from 'react';

import { StyledTab, StyledTabs } from '@components/common/FormStyledComponents';
import TournamentsAll from '@components/Tournaments/TournamentsAll/TournamentsAll';
import TournamentsAvailable from '@components/Tournaments/TournamentsAvailable/TournamentsAvailable';
import TournamentsEntered from '@components/Tournaments/TournamentsEntered/TournamentsEntered';

const a11yProps = (index: number) => {
  return {
    id: `action-tab-${index}`,
    'aria-controls': `action-tabpanel-${index}`,
  };
};

export const Home = () => {

  const [value, setValue] = useState(2);  

  const handleChange = (event: unknown, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div>
      <StyledTabs value={value} onChange={handleChange} textColor="primary" variant="fullWidth">
        <StyledTab label="All" {...a11yProps(0)} />
        <StyledTab label="Entered" {...a11yProps(1)} />
        <StyledTab label="Available" {...a11yProps(2)} />
      </StyledTabs>
      {value === 0 && <TournamentsAll />}
      {value === 1 && <TournamentsEntered />}
      {value === 2 && <TournamentsAvailable />}
    </div>
  );
};
